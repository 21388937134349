import { mapActions, mapGetters, mapMutations } from 'vuex'
import dialogView from './dialog-view/index.vue'

export default {
    name: 'setting-getaway',
    data () {
        return {
            page            : 1,
            showRemoveDialog: false,
            removedItem     : null
        }
    },
    components: {
        dialogView
    },
    computed: {
        ...mapGetters({
            list            : 'settingGetaway/list',
            limit           : 'settingGetaway/limit',
            listLength      : 'settingGetaway/listLength',
            isLoading       : 'settingGetaway/isLoading',
            isListLoading   : 'settingGetaway/isListLoading',
            openDialogView  : 'settingGetaway/openDialogView'
        }),
        headers () {
            return [
                {
                    text    : 'ID',
                    value   : 'value'
                },
                {
                    text    : 'Provider',
                    value   : 'provider'
                },
                {
                    text    : 'Name',
                    value   : 'name'
                },
                {
                    text    : 'Actions',
                    sortable: false,
                    value   : 'actions'
                }
            ]
        }
    },
    created () {
        this.fetchList()
    },
    methods: {
        ...mapActions({
            fetchList   : 'settingGetaway/GET_LIST',
            remove      : 'settingGetaway/DELETE'
        }),
        ...mapMutations({
            changeDialogView: 'settingGetaway/CHANGE_DIALOG_VIEW',
            changeEditId    : 'settingGetaway/CHANGE_EDIT_ID'
        }),
        changeDialog (status = false, item = null) {
            this.changeEditId(item.id)
            this.changeDialogView(status)
        }
    }
}
