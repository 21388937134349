import { mapActions, mapGetters, mapMutations } from 'vuex'
import general from '../general/index.vue'

export default {
    name: 'setting-getaway-dialog-view',
    data () {
        return {
            dialog      : true,
            activeTab   : 'general',
        }
    },
    components: {
        general
    },
    watch: {
        openDialogView () {
            this.dialog = this.openDialogView
        }
    },
    computed: {
        ...mapGetters({
            currentItem     : 'settingGetaway/currentItem',
            openDialogView  : 'settingGetaway/openDialogView',
            basicLocales    : 'setting/basicLocales',
            editId          : 'settingGetaway/editId',
            isLoading       : 'settingGetaway/isLoading'
        }),
        tabs () {
            return [
                {
                    name: 'General',
                    slug: 'general',
                    id  : 1
                }
            ]
        }
    },
    created () {
        if (!this.basicLocales.length) {
            this.fetchBasicLocales()
        }
        
        if (this.editId) {
            this.fetchItem()
        }
    },
    methods: {
        ...mapActions({
            fetchItem           : 'settingGetaway/GET_LIST_ITEM',
            fetchBasicLocales   : 'setting/GET_BASIC_LOCALES'
        }),
        ...mapMutations({
            changeDialogView    : 'settingGetaway/CHANGE_DIALOG_VIEW',
            changeEditId        : 'settingGetaway/CHANGE_EDIT_ID',
            setContent          : 'settingGetaway/SET_ITEM'
        }),
        changeCloseDialog () {
            this.dialog = false
            
            this.changeDialogView(false)
            this.changeEditId('')
        },
        save(){
            this.$refs['general'].$emit('event-save');
        }
    },
    destroyed () {
        this.setContent(null)
    }
}
