import mixins from '@/mixins'
import validation from '@/mixins/validation'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'

export default {
    name    : 'setting-getaway-general',
    mixin   : [mixins, validation],
    data () {
        return {
            valid       : false,
            search      : null,
            searchInput : null,
            providers   : [],
            payload     : {
                value       : ''
            }
        }
    },
    watch   : {
        currentItem () {
            if (this.currentItem && this.editId) {
                this.setData()
            }
        }
    },
    computed: {
        ...mapGetters({
            profile         : 'profile/profile',
            isLoading       : 'settingGetaway/isLoading',
            currentItem     : 'settingGetaway/currentItem',
            settings        : 'templates/settings',
            list            : 'settingGetaway/list',
            editId          : 'settingGetaway/editId',
            listProviders   : 'settingGetawayProviders/list'
        })
    },
    created () {
        if (this.currentItem) {
            this.setData()
        }
        
        this.$on('event-save', () =>{
            this.submit();
        });
        
        if (!this.settings) this.fetchSettings();
        
        this.setProviders();
    },
    mounted () {},
    methods: {
        ...mapActions({
            update          : 'settingGetaway/UPDATE',
            create          : 'settingGetaway/CREATE',
            fetchSettings   : 'templates/GET_SETTING',
            fetchList       : 'settingGetawayProviders/GET_LIST',
        }),
        ...mapMutations({}),
        changeProvider (value) {
            this.payload.value = value;
        },
        async setProviders () {
            if(!this.listProviders.length){
                await this.fetchList();
            }
            
            if(this.listProviders){
                for(let i = 0; i < this.listProviders.length; i++){
                    this.providers.push({
                        id      : this.listProviders[i].id,
                        label   : '#'+this.listProviders[i].id+' - '+this.listProviders[i].provider+' ('+this.listProviders[i].name+')'
                    });
                }
            }
        },
        setData () {
            Object.assign(this.payload, this.currentItem)
        },
        createRequestPayload () {
            const formData = new FormData()
            
            for (const i in this.payload) {
                formData.append(snakeCase(i), this.payload[i] || '')
            }
            
            this.currentItem ? formData.append('_method', 'patch') : ''
            
            return formData
        },
        submit () {
            this.$refs.form.validate()
            
            if (this.valid) {
                if (this.currentItem) {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_updated'))
                        this.setData();
                    });
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_created'));
                    });
                }
            }
        }
    }
}
